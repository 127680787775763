import React, { useState, useEffect, useRef, useMemo } from "react";
import { Popup, Input, SearchIcon, CloseIcon } from "@fluentui/react-northstar";
import "./searchAndFilterColumnDynamic.css";
import Constant from "../../utility/constants";
import { Checkbox } from "@fluentui/react-northstar";
import APIEndPoints from "../../utility/apiendpoints";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { postData } from "../../services/customApis";
import { Loader } from "@fluentui/react-northstar";
import { Button } from "@fluentui/react-northstar";
import { UseSearchAndFilterColumnContext } from "../../context/searchandfiltercolumncontext/searchAndFilterColumnContext";

const PAGE_SIZE = Constant.INTERNAL_APPS_GENERAL_CONSTANTS.FIELD_LEVEL_PAGE_SIZE;

export function SearchAndFilterColumnDynamic(props) {
  const { searchAndFilterColumnsData, handleSearchAndFilterColumnsData } = UseSearchAndFilterColumnContext();
  const params = props?.controlParams;
  const controlName = params?.controlObj?.controlName;
  let { userInfo } = UseUserInfoContext();
  const [open, setOpen] = useState(false);
  const [textBoxValue, setTextBoxValue] = useState("");
  const [noRecordsFound, setNoRecordsFound] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  const [searchPageInfo, setSearchPageInfo] = useState({});
  const searchTextBoxValue = useRef("");
  const textBoxValueRef = useRef("");
  const delayLoadPageInfo = useRef({});
  const [filteredColumnResultsApplied, setFilteredColumnResultsApplied] =
    useState([]);
  const [selectedValuesTrue, setSelectedValuesTrue] = useState([]);
  const [width, setWidth] = useState(0);
  const widthRef = useRef(null);
  const popupRef = useRef(null);
  const [isSearchClicked, setIsSearchClicked] = useState(0);
  const [timer, setTimer] = useState(null);
  const filteredColumnResultsApplied_Copy = useRef([]);
  let selectedParams = props?.itemsSelected;
  useEffect(() => {
    if (selectedParams?.length > 0) {
      let selectedPillValues = selectedParams?.map((item) => ({
        key: item,
        label: item,
        name: item,
        selected: true,
      }));
      setSelectedValuesTrue(selectedPillValues);
    }
  }, [selectedParams]);

  const handleClickOutside = async (event, data) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setOpen(false);
      let checkedItemsList = filteredColumnResultsApplied?.filter((item) => {
        return item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
        &&
        item?.selected == true          
    });
      props?.columnFilteringUpdateMethod(
        params?.controlObj,
        params?.searchOptionID,
        params?.eachSection,
        params?.eachRow,
        checkedItemsList
      );

      setTextBoxValue("");
      searchTextBoxValue.current = "";
      noRecordsFound?.length > 0 &&
        setNoRecordsFound(() => {
          return "";
        });
      setOpenState();      
      setSelectedValuesTrue(checkedItemsList);      
      if (params?.controlObj?.selectAll && filteredColumnResultsApplied_Copy.current?.length > 0) {         
        let filteredCopyNonSelectAll = filteredColumnResultsApplied_Copy.current.filter((item) => item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase())
        let filteredColumnResultsAppliedArr = removeDuplicates(
          checkedItemsList,
          filteredCopyNonSelectAll
        )                  
          let selectAll = [];
          selectAll.push({
            name: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP,
            key: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY,
            label: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP,
            selected: checkedItemsList?.length == filteredCopyNonSelectAll?.length ? true : false,
          });
          filteredColumnResultsAppliedArr = [...selectAll, ...filteredColumnResultsAppliedArr]        
        setFilteredColumnResultsApplied(() => {
          return filteredColumnResultsAppliedArr
        });
      }
      else {
        setFilteredColumnResultsApplied(() => {
          return removeDuplicates(
            checkedItemsList,
            filteredColumnResultsApplied_Copy.current
          )
        });
      }     
      await setLoading(true);
    }
  };

  useEffect(() => {
    if (props?.isFromSearch) {
      updateWidth();
      window.addEventListener("resize", updateWidth);
    }
    return () => {
      if (props?.isFromSearch) {
        window.removeEventListener("resize", updateWidth);
      }
    };
  }, []);

  const updateWidth = () => {
    if (widthRef.current) {
      const sourceWidth = widthRef.current.offsetWidth;
      setWidth(sourceWidth);
    }
  };
  const removeSelectedValuesCloseIcon = (currentitem) => {
    let filteredColumnResultsAppliedArr = selectedValuesTrue.filter(item => item?.name !== currentitem?.name) ?? [];
    if (filteredColumnResultsApplied?.length > 0) {
      filteredColumnResultsAppliedArr = [...filteredColumnResultsApplied];
      let index = filteredColumnResultsApplied?.findIndex(
        (item) => item?.name === currentitem?.name
      );
      if (index != -1) {
        filteredColumnResultsAppliedArr[index]["selected"] = false;
        if(params?.controlObj?.selectAll){
          let selectAllIndex = filteredColumnResultsApplied?.findIndex(
            (item) => item?.key?.toLowerCase() == Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
          );
          if (selectAllIndex != -1) {
            filteredColumnResultsAppliedArr[selectAllIndex]["selected"] = false;
          }          
        }
      }
      setFilteredColumnResultsApplied(() => {
        return filteredColumnResultsAppliedArr;
      });
    }
    setSelectedValuesTrue(() => {
      return filteredColumnResultsAppliedArr.filter(
        (item) => item?.selected === true && item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
      );
    });
    props?.columnFilteringUpdateMethod(
      params?.controlObj,
      params?.searchOptionID,
      params?.eachSection,
      params?.eachRow,
      filteredColumnResultsAppliedArr?.filter((item) => item?.selected === true && item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase())
    );

  };

  const setOpenState = () => {
    if (!open) {
      setOpen(() => {
        return true;
      });
    }
  };
  const setLoadingState = () => {
    if (!loading) {
      setLoading(() => {
        return true;
      });
    }
  };
  const textBoxChange = (event, addSpace) => {
    if (addSpace) {
      setTextBoxValue((prevVal) => {
        return prevVal + " ";
      });
    } else {
      setTextBoxValue(event?.target?.value);
    }

  };
  const sortDropdown = async () => {
    let selectedDropdownValues = params?.controlObj?.selectedValue?.value;
    if (selectedDropdownValues?.length > 0) {
      let filteredColumnResultsAppliedCopy = [...filteredColumnResultsApplied];
      let uniqueArray = Object.values(
        filteredColumnResultsAppliedCopy.reduce((acc, cur) => {
          acc[cur.name] = cur;
          return acc;
        }, {})
      );
      uniqueArray.sort((a, b) => {
        if (a.selected === b.selected) {
          return 0;
        }
        return a.selected ? -1 : 1;
      });
      setFilteredColumnResultsApplied(() => {
        return uniqueArray;
      });
    }
  };
  const specialCharactersValidation = () => {
    let allowedSplChars = props?.specialCharacters;
    let regex = new RegExp(allowedSplChars?.replace("/", "//"));
    if (!regex.test(textBoxValue?.replace(/\s+/g, ""))) {
      setNoRecordsFound(() => {
        return Constant.SEARCH_AND_FILTER_COM_CONSTANTS
          .SPECIAL_CHARACTERS_NOT_ALLOWED;
      });
      return false;
    }
    return true;
  };
  const onClickSearchIcon = async () => {
    if (specialCharactersValidation(textBoxValue)) {
      setLoadingState();

      await setFilteredColumnResultsApplied(() => {
        return filteredColumnResultsApplied?.filter(
          (item) => {
            return item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
            &&
            item?.selected == true          
        }
        );
      });
      if (textBoxValue?.length > 2) {
        await performSearchOnColumn(false, true);
      }
      else {
        setLoading(false);
      }
      //}
    }
  };
  const onClickCloseIcon = async (isCloseClicked) => {
    let updatedArray = [];
    let text = textBoxValue;
    setLoading(true);
    setTextBoxValue("");
    noRecordsFound?.length > 0 &&
      setNoRecordsFound(() => {
        return "";
      });
    if (
      isCloseClicked
      //&& text?.includes("items selected")
    ) {
      setOpenState();
      if (
        filteredColumnResultsApplied?.filter((item) => {
          return item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
          &&
          item?.selected == true          
      })
          ?.length > 0
      ) {
        updatedArray = filteredColumnResultsApplied?.map((item) => {
          if (item?.selected === true) {
            return {
              ...item,
              selected: false,
            };
          } else {
            return item;
          }
        });
        await setFilteredColumnResultsApplied(() => {
          return updatedArray;
        });
        props?.columnFilteringUpdateMethod(
          params?.controlObj,
          params?.searchOptionID,
          params?.eachSection,
          params?.eachRow,
          updatedArray?.filter((item) => item?.selected === true),
          true
        );
      }

    } else {

    }
    await setLoading(false);
  };
  const removeDuplicates = (arr1, arr2) => {
    let combinedArray = [...arr1, ...arr2];
    let uniqueArray = Object.values(
      combinedArray.reduce((acc, cur) => {
        if (!acc[cur.name] || (acc[cur.name] && cur.selected)) {
          acc[cur.name] = cur;
        }
        return acc;
      }, {})
    );
    uniqueArray.sort((a, b) => {
      if (a.selected === b.selected) {
        return 0;
      }
      return a.selected ? -1 : 1;
    });
    if (uniqueArray?.length > 0) {
      return uniqueArray;
    } else {
      return [];
    }
  };

  const performSearchOnFirstClick = async (isFromOnClick) => {
    await setOpenState();
    if (isFromOnClick && !filteredColumnResultsApplied.length > 0) {
      await performSearchOnColumn(true);
    }
    else {
      setLoading(false);
    }

  };
  const performSearchOnColumn = async (isFromOnClick, clearExistingData) => {
    let textboxcontentSearch = searchTextBoxValue.current?.trim();
    if (
      !clearExistingData &&
      !isFromOnClick &&
      pageInfo != undefined &&
      pageInfo?.rowNumber == pageInfo?.totalCount
    ) {
      !loading && setLoading(false);
      // return;
    } else {
      await setOpenState();
      await setLoadingState();
      await sortDropdown();
      let requestJSON = {
        filters: {
          fields: props.searchFilters ?? {},
        },
        pageInfo: {
          pageLength: params?.controlObj?.selectAll ? 0 : PAGE_SIZE,
          pageNumber:
            clearExistingData || isFromOnClick
              ? 1
              : delayLoadPageInfo.current?.rowNumber / PAGE_SIZE + 1
        },
      };
      const columnSearchPayload = {
        templateId: props?.templateDetails?.templateId?.toString() ?? "",
        fieldName: props?.appDetails?.appID ? props?.fieldName : null,
        searchText: btoa(unescape(encodeURIComponent(searchTextBoxValue?.current?.trim()))),
        searchQuery: props?.appSearchQueryValue,
        appId: props?.appDetails?.appID,
        requestJson: JSON.stringify(requestJSON),
      };
      await postData(
        columnSearchPayload,
        APIEndPoints.GET_DATA_LIBRARY_COLUMN_FILTER_DATA(
          props?.appDetails?.contentTypeId,
          userInfo?.teams?.companyId
        )
      )
        .then(async (filterValuesResponseColumn) => {
          if (filterValuesResponseColumn?.data?.status === "success") {
            if (
              filterValuesResponseColumn?.data?.resultData?.values?.length < 1
            ) {
              setNoRecordsFound(() => {
                return Constant.SEARCH_AND_FILTER_COM_CONSTANTS.NO_RECORD_FOUND;
              });
              setLoading(false);
              return;
            } else {
              setNoRecordsFound(() => {
                return "";
              });
            }
            if (
              filterValuesResponseColumn?.data?.resultData?.values?.length > 0
            ) {
              if (
                Object.keys(
                  filterValuesResponseColumn?.data?.resultData?.pageInfo
                )?.length > 0
              ) {
                if (textboxcontentSearch?.length > 0) {
                  setSearchPageInfo(
                    filterValuesResponseColumn?.data?.resultData?.pageInfo
                  );
                }
                else {
                  setPageInfo(
                    filterValuesResponseColumn?.data?.resultData?.pageInfo
                  );
                }

              } else {
                setLoading(false);
                return;
              }
              const existingSelectedValues =
                filteredColumnResultsApplied?.filter(
                  (item) => {
                    return item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
                    &&
                    item?.selected == true          
                }
                );
              let filterValuesResponseColumnArr =
                filterValuesResponseColumn?.data?.resultData?.values?.map(
                  (item) => ({
                    name: item,
                    key: item,
                    label: item,
                    selected: false,
                  })
                ) || [];
              if (filteredColumnResultsApplied_Copy.current?.length > 0) {
                if (clearExistingData) {
                  setFilteredColumnResultsApplied(() => {
                    return removeDuplicates(
                      existingSelectedValues,
                      filterValuesResponseColumnArr
                    );
                  })
                }
                else if (textboxcontentSearch?.length > 0) {
                  let removeDuplicatesFilterValues = removeDuplicates(
                    filteredColumnResultsApplied,
                    filterValuesResponseColumnArr
                  );
                  setFilteredColumnResultsApplied(removeDuplicatesFilterValues);
                }

                else {
                  let removeDuplicatesFilterValues = removeDuplicates(
                    filteredColumnResultsApplied,
                    filterValuesResponseColumnArr
                  );
                  setFilteredColumnResultsApplied(removeDuplicatesFilterValues);
                  let filterValuesResponseColumnArr_String = JSON.stringify(filterValuesResponseColumnArr);
                  let filterValuesResponseColumnArr_JSON = JSON.parse(filterValuesResponseColumnArr_String);
                  filteredColumnResultsApplied_Copy.current = [...filteredColumnResultsApplied_Copy.current, ...filterValuesResponseColumnArr_JSON]

                }
              } else {
                
                if(params?.controlObj?.selectAll)
                {
                  let selectAll = [];
                  selectAll.push({name: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP,
                    key: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY,
                    label: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP,
                    selected: filterValuesResponseColumnArr?.length == selectedValuesTrue?.length ? true : false,});
                  let filterValuesResponseColumnArr_filtered = removeDuplicates(
                    selectedValuesTrue,
                    filterValuesResponseColumnArr
                  );
                  setFilteredColumnResultsApplied(() => {
                    return [...selectAll,...filterValuesResponseColumnArr_filtered]
                  })
                }
                else{                
                setFilteredColumnResultsApplied(() => {
                  return removeDuplicates(
                    selectedValuesTrue,
                    filterValuesResponseColumnArr
                  );
                })
                }
                let filterValuesResponseColumnArr_String = JSON.stringify(filterValuesResponseColumnArr);
                let filterValuesResponseColumnArr_JSON = JSON.parse(filterValuesResponseColumnArr_String);
                filteredColumnResultsApplied_Copy.current = filterValuesResponseColumnArr_JSON;

              }
            }
            setLoading(false);
          } else {
            setNoRecordsFound(() => {
              return Constant.SEARCH_AND_FILTER_COM_CONSTANTS.NO_RECORD_FOUND;
            });
            setLoading(false);
          }
        })
        .catch((apiError) => {
          setNoRecordsFound(() => {
            return Constant.SEARCH_AND_FILTER_COM_CONSTANTS
              .UNABLE_TO_FETCH_DATA;
          });
          setLoading(false);
        });
    }
  };

  const checkboxChanged = (event, checkboxProps) => {
    let filteredColumnResultsAppliedArr = [...filteredColumnResultsApplied];
    if(checkboxProps?.name?.toLowerCase() == Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP?.toLowerCase() && params?.controlObj?.selectAll){
      filteredColumnResultsAppliedArr.map((item)=>{
        item.selected = checkboxProps?.checked;  
      })
    }
    else{
    let index = filteredColumnResultsApplied?.findIndex(
      (item) => item?.name === checkboxProps?.name
    );
    if (index != -1) {
      filteredColumnResultsAppliedArr[index]["selected"] =
        checkboxProps?.checked;
        if(params?.controlObj?.selectAll){
        let selectAllIndex = filteredColumnResultsApplied?.findIndex(
          (item) => item?.key?.toLowerCase() == Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
        );
        if (selectAllIndex != -1) {
          let filteredNonSelectAll = filteredColumnResultsAppliedArr.filter((item)=> {
            return item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
            &&
            item?.selected == true          
        })
          let filteredCopyNonSelectAll = filteredColumnResultsApplied_Copy.current.filter((item)=> item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase())
          if(filteredNonSelectAll?.length == filteredCopyNonSelectAll?.length){
          filteredColumnResultsAppliedArr[selectAllIndex]["selected"] = true;
          }
          else {
          filteredColumnResultsAppliedArr[selectAllIndex]["selected"] = false;
          }
        }
      }
    }
  }  
    setFilteredColumnResultsApplied(() => {
      return filteredColumnResultsAppliedArr;
    });
  };

  useEffect(() => {
    const dropdownOptions = document?.getElementById(
      "filteredColumnResultsCheckBox_Dynamic"
    );
    const handleScroll = () => {
      if (!loading && !params?.controlObj?.selectAll) {
        if (
          dropdownOptions.scrollHeight - dropdownOptions.scrollTop <=
          dropdownOptions.clientHeight + 10
        ) {
          delayLoadPageInfo.current = {};
          if (searchTextBoxValue.current?.length > 0 && textBoxValueRef.current?.trim() == searchTextBoxValue.current?.trim()) {
            delayLoadPageInfo.current = searchPageInfo;
          }
          if (searchTextBoxValue.current?.length < 1 && textBoxValueRef.current?.trim()?.length == 0) {
            delayLoadPageInfo.current = pageInfo;
          }
          if (Object.keys(delayLoadPageInfo.current).length > 0) {
            if (
              delayLoadPageInfo.current.rowNumber <= delayLoadPageInfo.current.totalCount &&
              !noRecordsFound.length > 0
            ) {
              if (delayLoadPageInfo.current.rowNumber == delayLoadPageInfo.current.totalCount) {
                return;
              }
              setLoading(true);
              setTimeout(() => {
                performSearchOnColumn();
              }, 1000);
            }
          }
        }
      }
    };
    dropdownOptions?.addEventListener("scroll", handleScroll);

    return () => {
      dropdownOptions?.removeEventListener("scroll", handleScroll);

    }
  }, [noRecordsFound, pageInfo, loading, searchPageInfo]);

  const popupContent = (
    <div>
      {loading == true && filteredColumnResultsApplied?.length == 0 ? (
        <div className={`filteredColumnResultsCheckBoxLoader_Dynamic`}>
          <Loader />
        </div>
      ) : <> {loading == false && noRecordsFound.length > 0 &&
        <div className="recordsMessage_Dynamic">{noRecordsFound}</div>}
        {<div key={controlName}>
          {filteredColumnResultsApplied?.length > 0 ? (
            <div
              className="filteredColumnResultsCheckBox_Dynamic"
              id="filteredColumnResultsCheckBox_Dynamic"
            >
              {filteredColumnResultsApplied.map((item) => (
                <div className="checkBoxDiv_Dynamic">
                  <Checkbox
                    key={item?.key}
                    name={item?.name}
                    label={item?.label}
                    onChange={checkboxChanged}
                    checked={item?.selected}
                    title={item?.name}
                    className={`checkBoxClass_Dynamic ${item?.selected ? "showOnlyOnChecked_Dynamic" : ""
                      }`}
                  />

                </div>
              ))}
              {loading && (
                <div className={`filteredColumnResultsCheckBoxLoader_Dynamic`}>
                  <Loader />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        }</>}
    </div>
  );
  useEffect(() => {
    textBoxValueRef.current = textBoxValue?.length>0 ? textBoxValue : "";
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      if (textBoxValue?.trim()?.length > 2) {
        setNoRecordsFound(() => {
          return "";
        });
        searchTextBoxValue.current = textBoxValue;
        onClickSearchIcon();
      }
      else if (textBoxValue?.length > 0) {
        setNoRecordsFound(() => {
          return Constant.SEARCH_AND_FILTER_COM_CONSTANTS.MINIMUM_CHARACTERS;
        });
        let selectedItemList = filteredColumnResultsApplied?.filter((item) => item?.selected === true)
        setFilteredColumnResultsApplied(selectedItemList);
        searchTextBoxValue.current = "";
      }
      else {
        searchTextBoxValue.current = "";
        setNoRecordsFound(() => {
          return "";
        });
        let filteredColumnResultsAppliedArr_filtered = filteredColumnResultsApplied?.filter((item) => {
          return item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase()
          &&
          item?.selected == true          
      });      
      if (params?.controlObj?.selectAll && filteredColumnResultsApplied_Copy.current?.length > 0) {         
        let filteredCopyNonSelectAll = filteredColumnResultsApplied_Copy.current.filter((item) => item?.key?.toLowerCase() !== Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY?.toLowerCase())
        let filteredColumnResultsAppliedArr = removeDuplicates(
          filteredColumnResultsAppliedArr_filtered,
          filteredCopyNonSelectAll
        )                  
          let selectAll = [];
          selectAll.push({
            name: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP,
            key: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP_KEY,
            label: Constant.INTERNALAPP_CONSTANTS.SELECTALL_FOR_INTERNALAPP,
            selected: filteredColumnResultsAppliedArr_filtered?.length == filteredCopyNonSelectAll?.length ? true : false,
          });
          filteredColumnResultsAppliedArr = [...selectAll, ...filteredColumnResultsAppliedArr]        
        setFilteredColumnResultsApplied(() => {
          return filteredColumnResultsAppliedArr
        });
      }
      else {
        setFilteredColumnResultsApplied(() => {
          return removeDuplicates(
            filteredColumnResultsAppliedArr_filtered,
            filteredColumnResultsApplied_Copy.current
          )
        });
      }
      }

    }, 1000);
    setTimer(newTimer);
    return () => clearTimeout(newTimer);

  }, [textBoxValue]);
  return (
    <div ref={popupRef}>
      <Popup
        open={open}
        position="below"
        offset={() => [0, 4]}
        onOpenChange={(event, data) => {
          handleClickOutside(event, data);
        }}
        closeOnScroll={false}
        trigger={
          <div ref={widthRef} className="search-filter-input-div-dynamic">
            <Input
              placeholder={props?.placeHolderText}
              clearable
              value={textBoxValue}
              onChange={(e) => {

                textBoxChange(e);
              }}
              onClick={() => {
                performSearchOnFirstClick(true);
              }}
              id={controlName}
              className={`search-filter-input_Dynamic ${controlName}`}
              onKeyDown={(event) => {
                if (event?.key === "Enter") {

                } else if (event?.key === " ") {

                  textBoxChange(event, true);
                }
              }}
            />
          </div>
        }
        content={{
          content: popupContent,
          styles: { width: `${width}px` },
          className: `${loading &&
            (filteredColumnResultsApplied == undefined ||
              filteredColumnResultsApplied == null ||
              filteredColumnResultsApplied?.length == 0)
            ? "initialLoaderSearchFilter_Dynamic"
            : "popupSearchFilter_Dynamic"
            }`,
        }}
      // trapFocus
      />
      {selectedValuesTrue?.length > 0 && (
        <div className="searchFilterPillsParent_Dynamic">
          {" "}
          {selectedValuesTrue.map(
            (item) => (
              (
                <div
                  className="searchFilterPills_span_Dynamic"
                  title={item?.label}
                >
                  <p>{item?.label} </p>
                  <CloseIcon
                    className="closeIcon-SearchFilterPills_Dynamic"
                    onClick={() => {
                      removeSelectedValuesCloseIcon(item);
                    }}
                  />
                </div>
              )
            )
          )}
        </div>
      )}
    </div>
  );
}
